@import "../../scss/variables";

footer {
  width: 100%;

  a {
    color: $color-white;
    text-decoration: none;
  }

  .footer {
    height: 61px;
    display: block;
    bottom: 0;
    z-index: 2;
    background: $color-dark-blue;
    border-top-style: solid;
    border-top-width: 2px;
    border-image-source: linear-gradient(90deg, $brand-primary-color, $brand-tertiary-color);
    border-image-slice: 1;

    &_container {
      height: 100%;
      font-size: 12px;
      margin: 0 auto;
      padding: 9px 0;
      text-align: center;


      @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 9px 25px;
      }

      p {
        margin-bottom: 0;
      }
    }
  }

}
