@import "../../scss/variables";

@keyframes HideList {
  from {
    transform: scaleY(1);
  }

  to {
    transform: scaleY(0);
  }
}

nav {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;

  .nav {
    display: block;
    top: 0;
    padding: 0 25px;
    z-index: 2;
    text-align: center;
    background-color: $color-dark-blue;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-image-source: linear-gradient(
      to right,
      $brand-primary-color,
      $brand-tertiary-color
    );
    border-image-slice: 1;
    box-shadow: 0 0 6px $color-dark-blue;

    &_container {
      max-width: 960px;
      margin: 0 auto;
      padding: 10px 0;
    }

    &_logo img {
      height: 100%;
    }

    &_toggle {
      width: 30px;
      height: 30px;
      cursor: pointer;

      &_line {
        width: 100%;
        height: 3px;
        background: $color-white;
        margin: 5px auto;
        border-radius: 1px;
        transition: all 0.3s;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;

        &.active {
          &:nth-child(1) {
            transform: rotate(45deg) translate(4px, 5px);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            transform: rotate(-45deg) translate(6px, -7px);
          }
        }
      }
    }

    &_links {
      display: none;

      @media (min-width: 768px) {
        display: flex;
        height: 100%;
      }

      &.active {
        display: block;
        position: fixed;
        top: 55px;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(33, 36, 74, 0.7);
      }
    }

    &_link {
      display: block;

      @media (min-width: 768px) {
        display: inline-block;

        &:not(:first-child) {
          margin-left: 30px;
        }
      }

      a {
        display: block;
        padding: 12px 0;
        font-size: 16px;
        font-weight: 500;
        color: white;
        text-decoration: none;
        text-transform: uppercase;
        transition-property: color;
        transition-duration: 0.2s;
        transition-timing-function: ease;

        @media (min-width: 768px) {
          display: inline;
          font-size: 14px;
          padding: 0;
        }

        &.active {
          color: $brand-primary-color;
        }
      }

      &:hover a {
        color: $brand-primary-color;
        text-decoration: none;
        opacity: 1;
      }
    }
  }
}
