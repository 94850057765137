@import "variables";
@import "animations";
@import "fonts";

$theme-colors: (
  "primary": $brand-primary-color,
  "secondary": $brand-secondary-color,
  "tertiary": $brand-tertiary-color,
  "danger": $brand-danger-color,
  "success": $brand-success-color,
  "warning": $brand-warning-color,
  "info": $brand-info-color,
  "light": $brand-light-color,
  "dark": $brand-dark-color,
);

@import "~bootstrap/scss/bootstrap";

html,
body {
  height: 100%;
  color: $color-white;
  background-color: $color-nearly-white;
}

* {
  font-family: $font-family;
}

a {
  color: $brand-tertiary-color;
  text-decoration: none;

  &:hover {
    opacity: 0.6;
    color: $brand-tertiary-color;

    &.btn {
      opacity: 1;
    }
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 26px;
}

h3 {
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}

h5 {
  font-weight: bold;
}
