$font-family: Prometo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, sans-serif;

$brand-primary-color: #27a2c7;
$brand-secondary-color: #202349;
$brand-tertiary-color: #a0d4cd;
$brand-danger-color: #dc3545;
$brand-success-color: #4caf50;
$brand-warning-color: #ffc107;
$brand-info-color: #17a2b8;
$brand-light-color: #f8f9fa;
$brand-dark-color: #333333;

$value-color-being-awesome: #27a2c7;
$value-color-working-together: #db2885;
$value-color-stuff-done: #ffce00;
$value-color-smashing-boundaries: #752f8a;
$value-color-being-real: #ff671b;

$color-white: #ffffff;
$color-nearly-white: #f3f3f3;

$color-black: #000000;
$color-grey: #888888;
$color-light-grey-2: #eee;
$color-light-grey: #bdbcbc;
$color-light-grey-3: #c2c2c2;
$color-mid-grey: #53565a;
$color-mid-grey-2: #999;
$color-mid-grey-3: #515151;
$color-dark-grey: #222222;
$color-dark-blue: #21244a;
