@import "../../scss/variables";

.loading {
  height: 100%;
  color: $brand-dark-color;

  .container {
    height: 100%;
    align-items: center;
  }
}
