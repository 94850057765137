@font-face {
  font-family: 'Prometo';
  src: url('../assets/fonts/prometo-light.woff');
  font-weight: 300;
}

@font-face {
  font-family: 'Prometo';
  src: url('../assets/fonts/prometo-regular.woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Prometo';
  src: url('../assets/fonts/prometo-medium.woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Prometo';
  src: url('../assets/fonts/prometo-medium.woff');
  font-weight: 700;
}
