@import '../../scss/variables';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: space-around;
}

.loading {
  width: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    text-align: center;
    font-size: 30px;
    margin-bottom: 3vh;
    font-weight: 100;
  }
}
